import { CircularProgress, Dialog } from "@mui/material";

import {
	CoAppEmbeddedVideoContainer, CoAppEmbeddedVideoIframe, CoAppEmbeddedVideoLoadingContainer,
	CoAppHelpIcon, CoAppPlayArrow, CoAppThumbnailOverlayText,
	CoAppThumbnailTooltip
} from "../styled/global.styled";

export default function CoAppEmbeddedVideo(props) {
	const {
		showVideo, setShowVideo, showThumbnail,
		setShowThumbnail, iframeTitle, iframeSrc,
		thumbnailSrc, thumbnailAltText, iconCustomStyles = {},
		thumbnailOverlayText = ""
	} = props;

	const handleShowVideo = () => {
		setShowVideo(true);
		setShowThumbnail(false);
	};

	return (
		<>
			<CoAppThumbnailTooltip
				open={showThumbnail}
				onOpen={() => setShowThumbnail(true)}
				onClose={() => setShowThumbnail(false)}
				title={
					<div style={{ position: "relative" }}>
						<CoAppThumbnailOverlayText color="info.main" variant="body1" >{thumbnailOverlayText}</CoAppThumbnailOverlayText>
						<CoAppPlayArrow onClick={handleShowVideo} />
						<img style={{ maxWidth: "300px" }} src={thumbnailSrc} alt={thumbnailAltText} />
					</div>
				}
			>
				<CoAppHelpIcon onClick={handleShowVideo} sx={iconCustomStyles} />
			</CoAppThumbnailTooltip>
			<Dialog open={showVideo} onClose={() => setShowVideo(false)} fullWidth={true} maxWidth="1000px">
				<CoAppEmbeddedVideoContainer>
					<CoAppEmbeddedVideoLoadingContainer>
						<CircularProgress />
					</CoAppEmbeddedVideoLoadingContainer>
					<CoAppEmbeddedVideoIframe
						title={iframeTitle}
						src={iframeSrc}
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					/>
				</CoAppEmbeddedVideoContainer>
			</Dialog>
		</>
	);
}