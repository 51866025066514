import { Drawer, styled } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";

export const CoAppNavigationTreeItemRoot = styled((props) => (
	< TreeItem {...props} />

))(({ theme, hashomepage = "false" }) => ({
	fontSize: hashomepage === "true" ? theme.typography.body3.fontSize : theme.typography.body1.fontSize,
	color: hashomepage === "true" ? theme.palette.text.secondary : theme.palette.text.primary,
	fontWeight: hashomepage === "true" ? 400 : theme.typography.body1.fontWeight,
	[`& .${treeItemClasses.content}`]: {
		paddingLeft: "0px",
		[`& .${treeItemClasses.label}`]: {
			fontWeight: "inherit",
			color: "inherit"
		},
		"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-selected:hover": {
			backgroundColor: theme.palette.primary._states.selected,
			borderRight: `3px solid ${theme.palette.primary.main}`,

		},
		"&:hover": {
			backgroundColor: theme.palette.primary._states.hover,
			cursor: "pointer",
		}
	},
	[`& .${treeItemClasses.disabled}`]: {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.body3.fontSize,
		fontWeight: 400,
		opacity: "1!important",
		"&:hover": {
			backgroundColor: "transparent",
			cursor: "default"
		},
	}

}));

export const CoAppDrawer = styled(Drawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		backgroundColor: "#FAFDFE",
		width: "320px",
		border: "none",
		boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)"
	}
}));

export const CoAppNavItem = styled("div")(({ theme }) => ({
	height: "40px",
	verticalAlign: "center",
	lineHeight: "40px",
	paddingLeft: "40px",
	textOverflow: "ellipsis",
	overflow: "hidden",
	whiteSpace: "nowrap"
}));

export const CoAppNavLogoContainer = styled("div")(({ theme }) => ({
	marginTop: "20px",
	marginLeft: "40px",
	width: "115px"
}));