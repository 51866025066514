import { Check, Close } from "@mui/icons-material";
import {
	DialogContent, Divider, InputLabel,
	List, ListItem, ListSubheader,
	Select, Stack, StepIcon,
	styled, TextField, Tooltip,
	tooltipClasses, Typography
} from "@mui/material";

export const ScimWizardContainer = styled("div")((props) => ({
	backgroundColor: "#F8F9FA",
	height: "100%",
}));

export const ScimWizardStepContainer = styled(Stack)((props) => ({
	paddingLeft: "75px",
	width: "75%"
}));

export const ScimWizardHeaderDivider = styled(Divider)((props) => ({
	width: "90%",
	margin: "10px auto",
}));

export const ScimWizardHeaderContainer = styled("div")((props) => ({
	width: "100%",
	marginTop: "20px",
}));

export const ScimWizardStepIcon = styled(StepIcon)(({ active, completed, theme }) => {
	if (active) {
		return {
			color: `${theme.palette.supporting.scimStepActive} !important`
		};
	} else if (completed) {
		return {
			color: `${theme.palette.supporting.scimStepCompleted} !important`
		};
	} else {
		return {
			backgroundColor: `${theme.palette.supporting.scimStepInactive} !important`
		};
	}
});

/**
 * Step 1. Select Idp.
 */

export const ScimWizardIdpContainer = styled("div")(({ theme }) => ({
	height: "185px",
	width: "219px",
	position: "relative",
	backgroundColor: theme.palette.primary._states.selected
}));

/**
 * End Step 1.
 */

/**
 * Step 2. Map groups.
 */

export const ScimWizardRoleNameCell = styled(Typography)(({ theme, superadmin }) => {
	if (superadmin === "true") {
		return {
			"&::after": {
				content: "' Required *'",
				color: theme.palette.error.main,
				fontSize: "0.7rem",
			}
		};
	} else {
		return null;
	}
});

export const ScimWizardStack = styled(Stack)((props) => ({
	margin: "auto",
	borderRadius: "5px",
	boxShadow: "0px 2px 2px 2px #00000026",
	width: "100%",
	border: "1px solid #00000026",
}));

export const ScimWizardStackItem = styled("div")(({ itemtype }) => ({
	borderRadius: itemtype === "header" ? "5px 5px 0px 0px" : itemtype === "footer" ? "0px 0px 5px 5px" : "0px",
	backgroundColor: itemtype === "header" ? "#F7F8F9" : "#FFFFFF",
	width: "auto",
	position: "relative",
	display: "flex",
	fontSize: "1rem",
	alignItems: "center",
	height: "50px",
	borderBottom: "1px solid #00000026",
	paddingLeft: "15px",
	color: ["header", "footer"].includes(itemtype) ? "#000000" : "#A2A2A2",
}));

export const ScimWizardGroupConnector = styled("div")((props) => ({
	color: props.isconnected ? "#2FBD70" : "red",
	paddingLeft: "20px",
	paddingRight: "35px"
}));

export const ScimWizardStackItemContent = styled("div")(({ itemtype }) => ({
	minWidth: "200px",
	marginLeft: itemtype === "header" ? "45px" : "0px",

}));

export const ScimWizardStackTextItemContent = styled("p")((props) => ({
	width: props.issetuppage ? "700px" : "150px",
	marginLeft: props.issetuppage && !props.isunmapped ? "20px" : "0px",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden"
}));

export const ScimWizardMultiSelectWithSearch = styled(Select)((props) => ({
	"&.MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none"
		},
		"&:hover fieldset": {
			border: "none"
		},
		"&.Mui-focused fieldset": {
			border: "none"
		}
	},
	backgroundColor: "#FFFFFF",
	width: "450px",
}));

export const ScimWizardInputLabel = styled(InputLabel)((props) => ({
	width: "200px",
}));

export const ScimWizardStackSearchField = styled(TextField)((props) => ({
	"& fieldset": {
		borderRadius: "50px 50px 50px 50px",
	}
}));

/**
 * End Step 2.
 */


export const ScimWizardDataGridStepContainer = styled(Stack)((props) => ({
	width: "100%"
}));

export const ScimWizardMappedResourceContainer = styled("div")(({ theme, ismapped = "false" }) => ({
	height: "415px",
	width: "260px",
	backgroundColor: ismapped === "true" ? "#F7F9F8" : "#E9504B1A",
	overflow: "hidden"
}));

export const ScimWizardMappedIcon = styled(Check)(({ theme }) => ({
	backgroundColor: theme.palette.supporting.scimStepCompleted,
	color: theme.palette.white_states.main,
	fontSize: "20px",
	borderRadius: "50%"
}));

export const ScimWizardUnmappedIcon = styled(Close)(({ theme }) => ({
	backgroundColor: theme.palette.error.main,
	color: theme.palette.white_states.main,
	fontSize: "20px",
	borderRadius: "50%"
}));

export const ScimWizardResourceNameTypography = styled(Typography)(({ theme }) => ({
	marginBottom: "10px",
	width: "250px"
}));

export const ScimWizardResourceList = styled(List)(({ theme, ismapped = "false" }) => ({
	width: "260px",
	backgroundColor: ismapped === "true" ? "#F7F9F8" : "#E9504B1A",
	[theme.breakpoints.between(1600, 2000)]: {
		height: "415px",
		width: "260px"
	},
	[theme.breakpoints.between(1280, 1600)]: {
		height: "300px",
		width: "200px"
	},
	[theme.breakpoints.between(960, 1280)]: {
		height: "300px",
		width: "200px"
	},
	overflowY: "auto",
	overflowX: "hidden",
	paddingTop: "0px",
	"&::-webkit-scrollbar": {
		backgroundColor: ismapped === "true" ? "#D7EBE5" : "#E0E2E1",
		width: "15px",
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	}
}));

export const ScimWizardConfirmationListHeader = styled(Typography)(({ theme }) => ({
	position: "relative",
	left: "40%",
	paddingBottom: "10px"
}));

export const ScimWizardResourceListHeader = styled(ListSubheader)(({ theme, ismapped = "false" }) => ({
	fontWeight: "500",
	backgroundColor: ismapped === "true" ? "#F7F9F8" : "#FCEDED",
	paddingTop: "10px",
	paddingBottom: "8px"
}));

export const ScimWizardResourceListItem = styled(ListItem)(({ theme }) => ({
	paddingLeft: "17px",
	height: "35px"
}));

export const ScimWizardResourceListTitle = styled(Typography)(({ theme }) => ({
	fontWeight: "500",
	color: "#000000"
}));

export const ScimWizardButtonContainer = styled("div")(({ theme }) => ({
	position: "absolute",
	top: "92%",
	left: "45%"
}));

export const ScimWizardThumbnailTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} arrow placement={props.placement ? props.placement : "bottom"} />
))(({ theme, opacity, placement }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: opacity === "true" ? "#FDFDFDE5" : "#FDFDFDF2",
		paddingTop: "4px",
		paddingBottom: "4px",
		maxWidth: "400px",
		border: `2px solid ${theme.palette.primary.main}`,
	},
	[`& .${tooltipClasses.arrow}`]: {
		"&::before": {
			backgroundColor: "#FDFDFDF2",
			boxShadow: placement === "bottom" ? "2px 2px 2px 2px rgba(0, 0, 0, 0.33)" : "2px 0px 2px 2px rgba(0, 0, 0, 0.33)"
		}
	}
}));

export const CoAppEmbeddedVideoContainer = styled("div")(({ theme }) => ({
	position: "relative",
	height: "700px"
}));

export const CoAppEmbeddedVideoLoadingContainer = styled("div")(({ theme }) => ({
	position: "absolute",
	top: 350,
	left: 900
}));

export const CoAppEmbeddedVideoIframe = styled("iframe")(({ theme }) => ({
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%"
}));

export const ScimWizardMailToLink = styled("a")(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.text.secondary,
	fontWeight: 600,
	marginRight: "10px",
}));

export const ScimWizardTryingAgain = styled("div")(({ theme }) => ({
	width: "98px",
	backgroundColor: theme.palette.action.disabledBackground,
	borderRadius: "20px",
	display: "flex",
	justifyContent: "center",
	padding: "5px"
}));

export const ScimWizardVideoOverlayText = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
	position: "absolute",
	top: 10,
	left: 62,
	width: "225px",
	textAlign: "center"
}));

export const ScimWizardExternalGroupMembersContainer = styled("div")(({ theme }) => ({
	width: "360px",
	backgroundColor: theme.palette.info._states.filledContainer,
	maxHeight: "460px",
	padding: "10px",
	overflow: "auto",
	"&::-webkit-scrollbar": {
		backgroundColor: theme.palette.text._states.selected,
		width: "15px",
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	}
}));

export const ScimWizardExternalGroupMembersList = styled("div")(({ theme }) => ({
	display: "flex"
}));

export const RoleValidationMissingPermissionsContainer = styled(DialogContent)(({ theme }) => ({
	backgroundColor: theme.palette.error._states.killSwitchActiveBase,
	height: "300px",
	margin: "20px",
	"&::-webkit-scrollbar": {
		backgroundColor: "#E0E2E1",
		width: "15px",
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	}
}));