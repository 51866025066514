import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import { getDataGridFakeData } from "../../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationIdentityProvider, selectUser } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import { CoAppDataGridActionsContainer } from "../../../global/styled/global.styled";

const groupIdpGroupColumns = ["actions", "name", "createdAt"];

export default function GroupSSO() {
	const dummyRows = getDataGridFakeData(groupIdpGroupColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { updateCoAppNavigation } = useCoAppNavigation();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("groupIdpGroups");
	const currentUser = useSelector(selectUser);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const navigate = useNavigate();
	const { groupId } = useParams();
	const { pathname, state } = useLocation();
	const [addIdPGroupsIsToggled, setAddIdPGroupsIsToggled] = useState(false);
	const [addIdpGroupsSearchInput, setAddIdpGroupsSearchInput] = useState("");
	const [idpGroups, setIdpGroups] = useState(dummyRows);
	const [idpGroupsToAdd, setIdpGroupsToAdd] = useState([]);
	const [availableIdpGroups, setAvailableIdpGroups] = useState([]);
	const [groupIdpGroups, setGroupIdpGroups] = useState([]);
	const [selectedIdpGroups, setSelectedIdpGroups] = useState([]);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [removeIdpGroupsIsToggled, setRemoveIdpGroupsIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");
	const [loading, setLoading] = useState(true);
	const [optionsLoading, setOptionsLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: identityProviderName ? `${identityProviderName} Group` : "Group",
			disableExport: true,
			editable: true,
			sortable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			),
		},
		{
			field: "createdAt",
			headerName: "Date Mapped",
			disableExport: true,
			editable: true,
			sortable: true,
			flex: 1,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			flex: .1,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridActionsContainer>
							<Delete
								onClick={() => {
									removeIdPGroupFromGroup(params.row.id);
								}}
							/>
						</CoAppDataGridActionsContainer>
					}
				/>
			),
		},
	];

	const addIdPGroupsToGroup = () => {
		axios.put(apiRoutes.updateGroupIdpGroups(groupId), { idpgroups: idpGroupsToAdd }, { headers: authHeader })
			.then(() => {
				init();
				toggleAddNewDialog();
				handleToastAlert(messageLevels.SUCCESS, messages.IDP_GROUPS_ADDITION_SUCCESS_MSG(identityProviderName || "IdP"));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.IDP_GROUPS_ADDITION_ERROR_MSG(identityProviderName || "IdP"));
			});
	};

	const addIdPGroupsToSelectedIdpGroups = (value) => {
		//value is an array of idpGroup names, we need to convert them to idpGroup objects to display the group name properly
		value = value.map((idpGroup) => {
			let tempArr = [...groupIdpGroups, ...availableIdpGroups];
			let foundIdpGroup = tempArr.find((row) => row.name === idpGroup);
			return foundIdpGroup;
		});
		setIdpGroupsToAdd(value);
	};

	const init = () => {
		axios.get(apiRoutes.getGroupIdpGroups(groupId), { headers: authHeader })
			.then((response) => {
				setGroupIdpGroups(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableIdpGroups = () => {
		let available = [];
		if (idpGroups && idpGroups.length > 0) {
			idpGroups.forEach((idpGroup) => {
				if (!groupIdpGroups.some((groupIdpGroup) => groupIdpGroup.name === idpGroup.externalGroupName)) {
					available.push({ externalGroupId: idpGroup.externalGroupId, name: idpGroup.externalGroupName });
				}
			});
		}
		setAvailableIdpGroups(available);
		setOptionsLoading(false);
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), { headers: authHeader })
			.then((response) => {
				setIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeIdPGroupFromGroup = (idpGroupId) => {
		axios.delete(apiRoutes.removeIdpGroupFromGroup(groupId, idpGroupId), { headers: authHeader })
			.then(() => {
				init();
				handleToastAlert(messageLevels.INFO, messages.IDP_GROUP_REMOVAL_SUCCESS_MSG(identityProviderName || "IdP", false));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.IDP_GROUP_REMOVAL_ERROR_MSG(identityProviderName || "IdP"));
			});
	};

	const bulkRemoveIdpGroupsFromGroup = () => {
		const deleteData = {
			idpgroupIds: selectedIdpGroups
		};
		axios.delete(apiRoutes.bulkRemoveIdpGroupsFromGroup(groupId), { headers: authHeader, data: deleteData })
			.then(() => {
				init();
				setRemoveIdpGroupsIsToggled(false);
				handleToastAlert(messageLevels.INFO, messages.IDP_GROUP_REMOVAL_SUCCESS_MSG(identityProviderName, selectedIdpGroups.length > 1));
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.IDP_GROUP_REMOVAL_ERROR_MSG(identityProviderName));
			});
	};

	const toggleAddNewDialog = () => {
		setAddIdPGroupsIsToggled(!addIdPGroupsIsToggled);

		if (!addIdPGroupsIsToggled) {
			initAvailableIdpGroups();
			setAddIdPGroupsIsToggled(true);
		} else {
			setAddIdPGroupsIsToggled(false);
			setAvailableIdpGroups([]);
			setIdpGroupsToAdd([]);
			setOptionsLoading(true);
		}
	};

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateCoAppNavigation(false, navigationItemReferences.groupNavItemIndex, groupNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				handleToastAlert(messageLevels.ERROR, messages.GROUP_NAME_UPDATE_ERROR_MSG);
			});
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then(() => {
			updateCoAppNavigation(true, navigationItemReferences.groupNavItemIndex);
			navigate(pages.groupManagement, { replace: true });
			handleToggleDeleteGroupDialog();
			handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(false));
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
			handleToggleDeleteGroupDialog();
		});
	};

	useEffect(() => {
		init();
		initIdpGroups();
		handleInitializeSortModel();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} ${identityProviderName ? identityProviderName + " " : "IdP "}Group Management`}
				subResourceType={identityProviderName ? `${identityProviderName} Group` : "IdP Group"}
				resourceType="Group"
				addOnClickHandler={toggleAddNewDialog}
				addIsPresent={true}
				editIsPresent={true}
				editOnClickHandler={handleToggleGroupEditDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={handleToggleDeleteGroupDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={groupIdpGroups}
				allowSelection={true}
				pinnedColumns={["actions", "__check__"]}
				targetResource={identityProviderName ? `${identityProviderName} groups` : "IdP groups"}
				parentResource="group"
				parentResourceName={state.name}
				bulkActionText="Remove"
				selectionModel={selectedIdpGroups}
				setSelectionModel={setSelectedIdpGroups}
				handleModalToggle={() => setRemoveIdpGroupsIsToggled(prev => !prev)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel={identityProviderName ? `${identityProviderName} Group(s)` : "IdP Group(s)"}
				addItemsIsToggled={addIdPGroupsIsToggled}
				addItemsToCollection={addIdPGroupsToGroup}
				addItemsToNewArray={addIdPGroupsToSelectedIdpGroups}
				dialogSearchPlaceholder={`Search ${identityProviderName ? identityProviderName + " " : ""}Groups`}
				dialogTitle={`Add ${identityProviderName ? identityProviderName + " " : ""}Group(s) to ${state.name} group`}
				items={availableIdpGroups}
				itemsToAdd={idpGroupsToAdd}
				searchInput={addIdpGroupsSearchInput}
				setSearchInput={setAddIdpGroupsSearchInput}
				toggleDialog={toggleAddNewDialog}
				loading={optionsLoading}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteGroupIsToggled}
				dialogTitle={`Delete ${state.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
				confirmClickHandler={confirmGroupDeletion}
				cancelClickHandler={handleToggleDeleteGroupDialog}
				actionText="Delete"
			/>

			<CoAppDestructiveConfirmationModal
				dialogOpen={removeIdpGroupsIsToggled}
				dialogTitle={`Remove selected (${selectedIdpGroups.length}) ${identityProviderName ? `${identityProviderName} ` : "IdP "}${selectedIdpGroups.length > 1 ? "groups" : "group"} from ${state.name}?`}
				dialogMessage={messages.IDP_GROUP_REMOVAL_MODAL_MSG(identityProviderName, state.name, "group")}
				confirmClickHandler={bulkRemoveIdpGroupsFromGroup}
				cancelClickHandler={() => setRemoveIdpGroupsIsToggled(false)}
				actionText="Remove"
			/>
			<CoAppEditNameModal
				dialogOpen={editGroupIsToggled}
				dialogTitle="Edit group name"
				changeHandler={handleGroupNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleGroupNameChangeSubmit}
				cancelClickHandler={handleToggleGroupEditDialog}
				actionText="Save"
				editNameError={groupNameError}
				setEditNameError={setGroupNameError}
			/>
		</>
	);
}