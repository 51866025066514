import { createTheme } from "@mui/material/styles";

export const lightModeTheme = createTheme({
	palette: {
		mode: "light",
		text: {
			primary: "#212121",
			coappPrimary: "#0195D5",
			secondary: "#00000099",
			disabled: "#00000061",
			error: "#CC2C2A",
			success: "#2E7D32",
			_states: {
				hover: "#0000000A",
				selected: "#00000014",
				focus: "#0000001F",
				focusVisible: "#0000004D",
			}
		},
		primary: {
			main: "#1D38BD",
			dark: "#00169F",
			light: "#2F4BD4",
			contrast: "#FFFFFF",
			_states: {
				hover: "#1D38BD0A",
				selected: "#1D38BD14",
				focus: "#1D38BD1F",
				focusVisible: "#1D38BD4D",
				outlinedBorder: "#1D38BD80",
				disabled: "#F5F5F5"
			}
		},
		secondary: {
			main: "#01D5AB",
			dark: "#008B5A",
			light: "#AEEDDB",
			contrast: "#FFFFFF",
			_states: {
				hover: "#01D5AB0A",
				selected: "#01D5BB14",
				focus: "#01D5AB1F",
				focusVisible: "#01D5AB4D",
				outlinedBorder: "#01D5AB80"
			}
		},
		action: {
			active: "#212121",
			hover: "#0000000A",
			selected: "#00000014",
			focus: "#0000001F",
			disabled: "#00000061",
			disabledBackground: "#0000001F",
		},
		error: {
			main: "#DE3730",
			dark: "#CC2C2A",
			light: "#E9504B",
			contrast: "#FFFFFF",
			_states: {
				hover: "#DE37300A",
				selected: "#DE373014",
				focusVisible: "#DE37304D",
				outlinedBorder: "#DE373080",
				killSwitchHover: "#E9504B26",
				killSwitchSelected: "#E9504B33",
				killSwitchActiveBase: "#E9504B1A"
			}
		},
		info: {
			main: "#0195D5",
			dark: "#00538C",
			light: "#80D0F0",
			contrast: "#FFFFFF",
			_states: {
				hover: "#0195D50A",
				selected: "#0195D514",
				focusVisible: "#0195D54D",
				outlinedBorder: "#0195D580",
				filledContainer: "#0195D51A"
			}
		},
		success: {
			main: "#2E7D32",
			dark: "#1B5E20",
			light: "#4CAF50",
			contrast: "#FFFFFF"
		},
		warning: {
			main: "#EF6C00",
			dark: "#E65100",
			light: "#FF9800",
			contrast: "#FFFFFF"
		},
		background: {
			default: "#FFFFFF",
			"paper-elevation-0": "#FFFFFF",
			"paper-elevation-1": "#FFFFFF",
			tableRow: "#F7F9F8"
		},
		supporting: {
			green: "#6FBA83",
			red: "#DB7A74",
			yellow: "#EAE481",
			scimStepCompleted: "#01D5AB",
			scimStepActive: "#008B5A",
			scimStepInactive: "#FFFFFF"
		},
		black_states: {
			main: "#000000",
			hover: "#0000000A",
			selected: "#00000014",
			focus: "#0000001F",
			focusVisible: "#0000004D",
			outlinedBorder: "#00000080",
			icon: "#0000008A"
		},
		white_states: {
			main: "#FFFFFF",
			hover: "#FFFFFF0A",
			selected: "#FFFFFF14",
			focus: "#FFFFFF1F",
			focusVisible: "#FFFFFF4D",
			outlinedBorder: "#FFFFFF80",
			disabled: "F5F5F5"
		},
		icon: {
			main: "#2fbd70",
			grey: "#0000008A",
			red: "#DE3730",
			green: "#008B5A"
		},
		paperTitle: {
			main: "#f50057",
		}
	},
	typography: {
		fontFamily: "Work Sans",
		h1: {
			fontSize: "1.8rem",
			lineHeight: "43.2px",
			fontWeight: 500
		},
		h3: {
			fontSize: "1.5rem",
			lineHeight: "24px",
			fontWeight: 500,
			fontStyle: "normal",
			letterSpacing: "0.5px"
		},
		h3_5: {
			fontSize: "1.3rem",
			lineHeight: "24px",
			fontWeight: 500,
			fontStyle: "normal",
			letterSpacing: "0.5px"
		},
		h4: {
			fontSize: "1.1rem",
			fontWeight: 500,
			lineHeight: "24px",
			letterSpacing: "0.15px"
		},
		h6: {
			fontSize: "1.15rem",
			fontWeight: 500,
			lineHeight: "31.2px",
		},
		body1: {
			fontSize: "1rem",
			lineHeight: "24px",
			fontWeight: 400
		},
		body2: {
			fontSize: "0.8rem",
			lineHeight: "20.02px",
			fontWeight: 400,
			bold: {
				fontWeight: 700
			}
		},
		body3: {
			fontSize: "10px",
			lineHeight: "14px",
		},
		subheader: {
			fontSize: "14px",
			fontWeight: 500,
			lineHeight: "30px",
			letterSpacing: "0.1px",
			fontFeatureSettings: "'liga' off, 'clig' off",
			fontStyle: "normal"
		},
		menuItemDense: {
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "24px",
			letterSpacing: "0.17px"
		},
		menuItem: {
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "24px",
			letterSpacing: "0.15px"
		},
		buttonSmall: {
			fontSize: "0.9rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "22px",
			letterSpacing: "0.46px",
		}
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: "#FAFDFE",
					height: "80px"
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				root: {
					"& .MuiChip-label": {
						maxWidth: "148px",
						textOverflow: "ellipsis",

						"&:hover": {
							cursor: "default"
						}
					},
				}
			}
		},
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: "#F0F9FD"
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#FFFFFF",
					borderRadius: "20px",
					"& .MuiOutlinedInput-root": {
						borderRadius: "20px",
						"&.Mui-focused": {
							borderColor: "#1D38BD"
						}
					}
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: "#00000099",
					"&.Mui-checked": {
						color: "#1D38BD"
					}
				}
			}
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					color: "#00000099",
					"&.Mui-checked": {
						color: "#1D38BD"
					},
					"&.Mui-disabled": {
						color: "#FFFFFF"
					}
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: "1px solid #e0e0e0",
					borderRadius: "5px",
					"& .MuiDataGrid-toolbarContainer": {
						backgroundColor: "#FFFFFF",
						color: "grey",
						"& .MuiButton-text": {
							color: "grey",
						}
					},
					"& .MuiDataGrid-pinnedColumnHeaders": {
						backgroundColor: "#FFFFFF"
					},
					"& .MuiDataGrid-pinnedColumns": {
						backgroundColor: "#FFFFFF"
					}
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					":hover": {
						backgroundColor: "#1D38BD0A"
					}
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: ({ ownerState }) => {
					if (ownerState?.variant === "destructive") {
						return {
							color: "#DE3730",
							":hover": {
								backgroundColor: "#1D38BD0A"
							}
						};
					} else {
						return {
							":hover": {
								backgroundColor: "#1D38BD0A"
							}
						};
					}
				},
			},
		},
	},
	breakpoints: {
		values: {
			"xs": 0,
			"sm": 600,
			"md": 800,
			"lg": 1400,
			"xl": 1536,
			"xxl": 1920
		},
	},
});

// TODO:: Add dark mode theme here
export const darkModeTheme = createTheme({});