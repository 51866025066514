import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import { getDataGridFakeData } from "../../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationSsoAndScim, selectUserId, setPermissions } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import ThreeDotMenu from "../../../global/components/three-dot-menu";

const roleUserColumns = ["actions", "user", "roles", "groups", "lastExtensionHeartbeat", "createdAt", "status"];

export default function RoleUsers() {
	const dummyRows = getDataGridFakeData(roleUserColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("roleUsers");
	const { viewUserPage, updateCoAppNavigation } = useCoAppNavigation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roleId } = useParams();
	const { pathname, state } = useLocation();
	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);
	const currentUserId = useSelector(selectUserId);

	const [availableUsers, setAvailableUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [roleUsers, setRoleUsers] = useState(dummyRows);
	const [userSeed, setUserSeed] = useState(1);
	const [usersToAdd, setUsersToAdd] = useState([]);
	const [addUsersToggled, setAddUsersToggled] = useState(false);
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [removeUsersIsToggled, setRemoveUsersIsToggled] = useState(false);
	const [roleNameInput, setRoleNameInput] = useState("");
	const [roleNameError, setRoleNameError] = useState("");
	const [loading, setLoading] = useState(true);
	const [optionsLoading, setOptionsLoading] = useState(true);

	const columns = [
		{
			editable: false,
			field: "user",
			valueGetter: (params) => params.value === "" ? "" : params.row.email,
			sortComparator: (v1, v2) => v1.localeCompare(v2),
			flex: 1,
			headerName: "User",
			pinnable: false,
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} isUser={true} isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />}
				/>
			),
		},
		{
			editable: false,
			field: "roles",
			sortable: false,
			flex: 1,
			headerName: "Roles",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Roles" isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />}
				/>
			),
		},
		{
			disableColumnMenu: true,
			editable: false,
			field: "groups",
			filterable: false,
			flex: 1,
			headerName: "Groups",
			pinnable: false,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Groups" />}
				/>
			),
		},
		{
			editable: false,
			field: "lastExtensionHeartbeat",
			width: 200,
			headerName: "Last extension heartbeat",
			pinnable: false,
			valueGetter: (params) => {
				const phoneHome = params.row.workstation?.lastPhoneHome;
				return phoneHome ? new Date(phoneHome) : null;
			},
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.row.workstation?.lastPhoneHome} />}
				/>
			),
		},
		{
			editable: false,
			field: "createdAt",
			width: 200,
			headerName: "Created",
			pinnable: false,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			),
		},
		{
			editable: false,
			field: "active",
			width: 75,
			headerName: "Status",
			sortable: true,
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={params.row.active}
							disabled={organizationSsoStatus || params.row.isCoAppSuperAdmin}
							onChange={() => handleUserStatusToggle(params.row.id, params.row.active === true ? "false" : "true")}
						/>
					}
				/>
			),
		},
		{
			field: "actions",
			flex: .1,
			sortable: false,
			type: "actions",
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							{
								organizationSsoStatus ?
									<ThreeDotMenu
										options={[
											{
												name: "View User",
												optionClickHandler: () => {
													viewUserPage(params, navigationItemReferences.roleNavItemIndex, roleId, state.name);
												},
											},
										]}
										isCoAppSuperAdmin={params.row.isCoAppSuperAdmin}
									/>
									:
									<ThreeDotMenu
										options={[
											{
												name: "Remove From Role",
												optionClickHandler: () => {
													removeUserFromRole(params.row.id);
												},
											},
											{
												name: "View User",
												optionClickHandler: () => {
													viewUserPage(params, navigationItemReferences.roleNavItemIndex, roleId, state.name);
												},
											},
										]}
										isCoAppSuperAdmin={params.row.isCoAppSuperAdmin}
									/>
							}
						</div>
					}
				/>
			),
		}
	];

	const handleCurrentUserPermissionUpdate = () => {
		axios.get(apiRoutes.getUser(1, currentUserId, "", null), { headers: authHeader })
			.then(res => {
				dispatch(setPermissions(res.data.permissions));
			})
			.catch(err => console.log(err));
	};

	const addUsersToRole = () => {
		const userIds = usersToAdd.map(u => u.id);
		axios.post(apiRoutes.addUsersToRole(roleId), { userIds: userIds }, {
			headers: authHeader
		}).then(() => {
			setUserSeed(userSeed + 1);
			toggleAddNewDialog();
			setUsersToAdd([]);
			handleToastAlert(messageLevels.SUCCESS, messages.USERS_ADDED_SUCCESS_MSG);
			const currentUserIsInCurrentRolesUsers = roleUsers.filter(user => user.id === currentUserId).length > 0;
			if (currentUserIsInCurrentRolesUsers) {
				handleCurrentUserPermissionUpdate();
			}
		}).catch(err => {
			console.error(err);
			handleToastAlert(messageLevels.ERROR, messages.USERS_ADDED_ERROR_MSG);
		});
	};

	const addUserToUsersToAdd = (value) => {
		//value is an array of user names, we need to convert them to user objects to display name + email in tooltip and add/remove based on email OR name
		value = value.map((user) => {
			let tempArr = [...roleUsers, ...availableUsers];
			let foundUser = tempArr.find((row) => row.name === user);
			//set the name to the email when the user has no firstname and lastname
			foundUser.name = foundUser.name === foundUser.email ? foundUser.email : `${foundUser.firstname} ${foundUser.lastname}`;
			return foundUser;
		});
		setUsersToAdd(value);
	};

	const init = () => {
		let userQueryObject = {
			roleId: roleId,
		};
		axios.get(apiRoutes.getUsers, {
			headers: authHeader,
			params: userQueryObject,
		})
			.then((res) => {
				setLoading(false);
				setRoleUsers(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const initUsersForAdd = () => {
		axios
			.get(apiRoutes.getUsers, {
				headers: authHeader,
			})
			.then((res) => {
				let users = res.data.filter((user) => {
					return !roleUsers.some((roleUser) => roleUser.id === user.id);
				});
				users = users.map((user) => {
					return {
						id: user.id,
						name: user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.email,
						email: user.email,
						firstname: user.firstname,
						lastname: user.lastname
					};
				});
				setAvailableUsers(users);
				setOptionsLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const removeUserFromRole = (userId) => {
		axios.delete(apiRoutes.removeUserFromRole(roleId, userId), { headers: authHeader })
			.then(() => {
				init();
				handleToastAlert(messageLevels.INFO, messages.USER_REMOVED_SUCCESS_MSG(false));
				const currentUserIsInCurrentRolesUsers = roleUsers.filter(user => user.id === currentUserId).length > 0;
				if (currentUserIsInCurrentRolesUsers) {
					handleCurrentUserPermissionUpdate();
				}
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.USER_REMOVED_ERROR_MSG);
			});
	};

	const bulkRemoveUsersFromRole = () => {
		const deleteData = {
			userIds: selectedUsers,
		};
		axios.delete(apiRoutes.bulkRemoveUsersFromRole(roleId), { headers: authHeader, data: deleteData })
			.then(() => {
				init();
				setRemoveUsersIsToggled(false);
				setSelectedUsers([]);
				handleToastAlert(messageLevels.INFO, messages.USER_REMOVED_SUCCESS_MSG(selectedUsers.length > 1));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.USER_REMOVED_ERROR_MSG);
			});
	};

	const handleUserStatusToggle = (id, state) => {
		let userJSON = {
			active: state
		};
		axios.put(apiRoutes.setUserActive(id), userJSON, { headers: authHeader })
			.then(() => {
				init();
			})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, messages.USER_TOGGLE_ERROR_MSG);
			});
	};

	const toggleDeleteRoleDialog = () => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then(() => {
			navigate(pages.roleManagement, { replace: true });
			updateCoAppNavigation(true, navigationItemReferences.roleNavItemIndex);
			handleToastAlert(messageLevels.INFO, messages.ROLE_DELETION_SUCCESS_MSG(false));
			const currentUserIsInCurrentRolesUsers = roleUsers.filter(user => user.id === currentUserId).length > 0;
			if (currentUserIsInCurrentRolesUsers) {
				handleCurrentUserPermissionUpdate();
			}
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.ROLE_DELETION_ERROR_MSG);
			toggleDeleteRoleDialog();
		});
	};

	const toggleEditRoleDialog = () => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameInput("");
		setRoleNameError("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === state.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleId), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				toggleEditRoleDialog();
				updateCoAppNavigation(false, navigationItemReferences.roleNavItemIndex, roleNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				handleToastAlert(messageLevels.ERROR, messages.ROLE_NAME_UPDATE_ERROR_MSG);
			});
	};

	const toggleAddNewDialog = () => {
		if (!addUsersToggled) {
			initUsersForAdd();
			setAddUsersToggled(true);
		} else {
			setAddUsersToggled(false);
			setAvailableUsers([]);
			setUsersToAdd([]);
			setOptionsLoading(true);
		}
	};

	useEffect(() => {
		init();
		handleInitializeSortModel();
	}, [userSeed, state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} User Management`}
				resourceType="Role"
				subResourceType="User"
				addIsPresent={organizationSsoStatus ? false : true}
				addOnClickHandler={toggleAddNewDialog}
				editIsPresent={state.isSuperAdmin ? false : true}
				editOnClickHandler={toggleEditRoleDialog}
				deleteIsPresent={state.isSuperAdmin ? false : true}
				deleteOnClickHandler={toggleDeleteRoleDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={roleUsers}
				pinnedColumns={
					organizationSsoStatus ?
						["actions", "name", "active"]
						:
						["actions", "__check__", "name", "active"]
				}
				allowSelection={organizationSsoStatus ? false : true}
				targetResource="users"
				parentResource="role"
				parentResourceName={state.name}
				bulkActionText="Remove"
				selectionModel={selectedUsers}
				setSelectionModel={setSelectedUsers}
				handleModalToggle={() => setRemoveUsersIsToggled(prev => !prev)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel="User(s)"
				addItemsIsToggled={addUsersToggled}
				addItemsToCollection={addUsersToRole}
				addItemsToNewArray={addUserToUsersToAdd}
				dialogTitle={`Add user(s) to ${state.name} role`}
				items={availableUsers}
				itemsToAdd={usersToAdd}
				toggleDialog={toggleAddNewDialog}
				loading={optionsLoading}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteRoleDialogOpen}
				dialogTitle={`Delete ${state.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={toggleDeleteRoleDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={removeUsersIsToggled}
				dialogTitle={`Remove ${selectedUsers.length > 1 ? "users" : "user"} from ${state.name} role?`}
				dialogMessage={`Are you sure that you want to remove the selected ${selectedUsers.length > 1 ? "users" : "user"} from the ${state.name} role?`}
				confirmClickHandler={bulkRemoveUsersFromRole}
				cancelClickHandler={() => setRemoveUsersIsToggled(false)}
				actionText="Remove"
			/>
			<CoAppEditNameModal
				dialogOpen={editRoleDialogOpen}
				dialogTitle="Edit role name"
				changeHandler={handleRoleNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleRoleNameChangeSubmit}
				cancelClickHandler={toggleEditRoleDialog}
				actionText="Save"
				editNameError={roleNameError}
				setEditNameError={setRoleNameError}
			/>
		</>
	);
}
