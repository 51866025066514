import { forwardRef, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view";

import pages from "../../constants/pages";
import useCoAppNavigation from "../../hooks/useCoAppNavigation";
import {
	selectExpandedItems, selectNavState, selectSelectedItem,
	setExpandedItems, setNavState, setSelectedItem
} from "../../redux/navigationSlice";
import { resetRuleWizardState } from "../../redux/ruleWizardSlice";

import { CoAppNavigationTreeItemRoot } from "./styled/coapp-nav-tree.styled";
import CustomContent from "./coapp-nav-tree-item-custom-content";

/**
 * Custom memo function to handle rerendering of the tree. This saves us from rerendering the tree when we don't need to, especially on user clicks
 * Returning false means we will rerender the tree
 * Returning true means we will not rerender the tree
 * see https://react.dev/reference/react/memo for more info on the memo HOC
 */
const shouldRerender = (prevProps, nextProps) => {
	let shouldNotRerender = true;
	prevProps.treeData.forEach((node, index) => {
		if (node.children.length !== nextProps.treeData[index].children.length) {
			shouldNotRerender = false;
		}
		if (node.children.length > 0) {
			node.children.forEach(child => {
				if (child.name === "Users") { //make sure we rerender when user views a user from groups or roles
					shouldNotRerender = false;
				}
			});
		}
	});

	return shouldNotRerender;
};

const CoAppSettingsNavTree = memo(function CoAppSettingsNavTree(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const navState = useSelector(selectNavState);
	const {
		handleOpenResourceHomePage,
		openScimWizard,
		reconstructParentPage,
		reconstructSubResourcePage,
		reconstructUserPage
	} = useCoAppNavigation();
	const expanded = useSelector(selectExpandedItems);
	const selected = useSelector(selectSelectedItem);

	const StyledTreeItem = (props) => {
		return <CoAppNavigationTreeItemRoot ContentComponent={forwardRef(CustomContent)} {...props} />;
	};

	const renderTree = (item) => {
		if (item.isTitle) {
			return (
				<div key={Math.random()} style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "13px" }}>
					<Typography variant="body3" color="text.secondary" >{item.name}</Typography>
				</div>
			);
		} else if (item.isClickable || item.isGroupingLabel) {
			return (
				<StyledTreeItem
					data-testid={`tag_${item.name}`}
					key={item.id}
					nodeId={item.id.toString()}
					label={item.name}
					disabled={item.isGroupingLabel && !item.hasHomePage} //disables first item when a specific sub item is selected, allows us to style the first item differently
					hashomepage={item.hasHomePage ? "true" : "false"}
					ContentProps={{
						onClick: () => handleNodeClick(item)
					}}
				>
					{Array.isArray(item.children) ? item.children.map((node) => renderTree(node)) : null}
				</StyledTreeItem>
			);
		} else if (item.name === "Divider") {
			return (<Divider key={Math.random()} sx={{ color: "#0000001F", marginTop: "20px" }} />);
		}
	};

	/**
	 * Handles updating the expanded state of the nave tree when a node is clicked.
	 * Note: Special case to identify when the trash/library nodes are clicked, as they have different behavior than the other nav nodes
	 */
	const handleExpansionOnNodeClick = (item) => {
		if (!["Library", "Trash"].includes(item.name)) {
			let tempExpanded = [...expanded];
			tempExpanded = tempExpanded.filter((nodeId) => !["3", "4"].includes(nodeId)); //handle closing the trash node when a user clicks on a different node
			dispatch(setExpandedItems([...tempExpanded, item.id.toString()]));
		} else {
			let tempExpanded = [...expanded];
			tempExpanded = tempExpanded.filter((nodeId) => ["3", "4"].includes(nodeId));
			dispatch(setExpandedItems([...tempExpanded]));
		}
	};

	/**
	 * Fired when a node in our tree is clicked.
	 * Handles the selection of the node that recieved the click event, redirection to the respective page, and updating the navigation state.
	 */
	const handleNodeClick = (item) => {
		const doNothingOnGroupLabelClick = item.isGroupingLabel && !item.hasHomePage; //temporary until we support home pages for all resource groupings
		if (item.isUserNavigationItem || item.page.includes("sso-scim") || doNothingOnGroupLabelClick) return; //dont do anything when the user nav item or scim wizard item is clicked
		handleExpansionOnNodeClick(item);
		let copyNavState = [...navState];
		let temp = copyNavState.flatMap((node) => node.children).filter(child => child.name !== "Trash");
		let foundItem = temp.length > 0 ? temp[0] : null;
		props.handleRedirect(item.page, { state: item.hasHomePage ? item : foundItem });
		copyNavState = handleNavigationUpdateOnClick(item);
		dispatch(setSelectedItem(item));
		dispatch(setNavState(copyNavState));
	};

	/**
	 * Handles updating the navigation state when a node is clicked. Essentially handles the closing of the tree nodes when a user clicks on a node.
	 * Notes: 
	 * - If keepOpen flag is true, it means a user has clicked into a specific group, role, plugin, or user to manage a sub item of the resource
	 * - If a homepage is clicked, we don't need to close the tree nodes or remove the children of the nodes
	 */
	const handleNavigationUpdateOnClick = (item) => {
		if (item.hasHomePage || ["999", "20"].includes(item.id.toString())) {
			return navState;
		}
		let copyNavState = [...navState];
		copyNavState = copyNavState.map((node) => {
			let tempNode = { ...node };
			if (node.children.length > 0 && !item.keepOpen) {
				if (node.name !== "Library") { // we dont need to remove the library nodes children
					tempNode.children = [];
				}
				tempNode.isStillSelected = false;
			} else if (node.name === "Organization") { //remove sso + scim child when a user clicks on a different node
				tempNode.children = [];
			} else {
				let hasSubChildrenIndex = tempNode.children.findIndex(child => child.name === "Users");
				if (hasSubChildrenIndex !== -1) {
					let tempChildren = tempNode.children.map((child, index) => {
						let tempChild = { ...child };
						if (index === hasSubChildrenIndex) {
							tempChild.children = [];
						}
						return tempChild;
					});
					tempNode.selectedResource = item.name;
					tempNode.children = tempChildren;
				}
				tempNode.isParentChangeEvent = false;
			}
			return tempNode;
		});
		return copyNavState;
	};

	const handleExpansion = (event, nodeId) => {
		if (["3", "4"].includes(nodeId)) {
			let tempExpanded = [...expanded];
			tempExpanded = tempExpanded.filter((node) => ["3", "4"].includes(node));
			dispatch(setExpandedItems([...tempExpanded, nodeId]));
		} else {
			let tempExpanded = [...expanded];
			tempExpanded = tempExpanded.filter((node) => !["3", "4"].includes(node));
			dispatch(setExpandedItems([...tempExpanded, nodeId]));
		}
	};

	const updateNavigation = () => {
		const pathItems = location.pathname.split("/").slice(1);
		if (pathItems.length === 2) {
			reconstructParentPage(pathItems);
		} else if (pathItems.length === 4) {
			reconstructSubResourcePage(pathItems, location);
		} else if (pathItems.length === 3 && pathItems[2] === "sso-scim") {
			openScimWizard(false);
		} else if (pathItems.length === 3 && pathItems[1] === "plugins") {
			handleOpenResourceHomePage(10, "Plugins", location.state, true);
		} else {
			reconstructUserPage(pathItems, location);
		}
	};

	useEffect(() => {
		if (selected.page !== location.pathname) {
			updateNavigation();
		}
		if (location.pathname === pages.newRule) {
			dispatch(resetRuleWizardState());
		}
	}, [props.treeData, location.pathname]);

	return (
		<TreeView
			expanded={expanded}
			selected={selected.id.toString()}
			onNodeSelect={handleExpansion}
		>
			{props.treeData.map((node) => renderTree(node))}
		</TreeView >
	);
}, shouldRerender);

export default CoAppSettingsNavTree;