import { TextField } from "@mui/material";

//TODO:: Should this really be extracted into a global component??
export default function CoAppTextField(props) {
	return (
		<TextField
			variant="standard"
			margin="normal"
			{...props}
		/>
	);
}